const en = {
    add: 'Add',
    addCategory: 'Add a new category',
    addRole: 'Add a new role',
    addUser: 'Add a new user',
    administrator: 'Administrator',
    admins: 'Administators',
    all: 'All',
    allowedCategories: 'Allowed categories',
    allUsers: 'All users',
    audioFile: 'Audio file',
    canDownloadEverything: 'Can download all files',
    categoryAuthorizationUpdatedSuccessfully: 'Category authorizations updated successfully',
    categoryDeletedSuccessfully: 'Category deleted successfully',
    categoryEditedSuccessfully: 'Category edited successfully',
    categoryPostedSuccessfully: 'Category added successfully',
    categories: 'Categories',
    categoriesLoading: 'Loading categories...',
    clickToEdit: 'Click on an element to edit it.',
    close: 'Close',
    communication: 'Communication',
    country: 'Country',
    countryNotEmpty: 'Country is required',
    deleteConfirmation: 'Are you sure you want to delete user',
    deleteUser: 'Delete user',
    denyAccess: 'Deny access to some users',
    department: 'Department',
    display: 'Display',
    dndExplanation: 'Drag and drop items to reorder categories and subcategories',
    docFile: 'Word Document',
    documentAuthorizationUpdatedSuccessfully: 'Document authorizations updated successfully',
    documentDeleted: 'Document deleted successfully',
    documentName: 'Document name',
    documentRenamedSuccessfully: 'Document renamed successfully',
    downloads: 'Downloads',
    editCategory: 'Edit category',
    editRole: 'Edit role',
    editUser: 'Edit user',
    email: 'Email address',
    emailInvalid: 'Email address is invalid',
    emailNotEmpty: 'Email is required',
    emailSent: 'Email sent',
    english: 'English',
    englishTitle: 'English title',
    errorMessage: 'There was an error',
    export: 'Export',
    exportStats: 'Export stats',
    exportVerb: 'Export',
    extranet: 'Extranet',
    fieldNotEmpty: 'Field is required',
    file: 'File',
    fileUploaded: 'File uploaded successfully',
    filterByRole: 'Filter by role',
    firstName: 'First name',
    firstNameNotEmpty: 'First name is required',
    footerDisclaimer: 'You have been personnally granted access to this website, we recommend you never share your credentials with any third party.',
    forgottenPassword: 'Forgotten password',
    french: 'French',
    frenchTitle: 'French title',
    history: 'History',
    isDownloadable: 'Allow files download',
    labelFr: 'French name',
    labelEn: 'English name',
    lastLogin: 'Last login',
    lastName: 'Last name',
    lastNameNotEmpty: 'Last name is required',
    loading: 'Loading...',
    locale: 'Language',
    localeNotEmpty: 'Locale is required',
    login: 'Login',
    logins: 'Logins',
    loginHistory: 'Login history',
    lastLoginsExplanation: 'Here are the 10 last login dates for user',
    lastDownloadsExplanation: 'Here are the 10 last file downloads for user',
    lastViewsExplanations: 'Here are the 10 last files viewed by user',
    logout: 'Logout',
    name: 'Name',
    nameNotEmpty: 'Name is required.',
    no: 'No',
    parentCategory: 'Parent category',
    password: 'Password',
    passwordRepeat: 'Repeat password',
    passwordNotEmpty: 'Password is required',
    passwordsDontMatch: 'Passwords don\'t match',
    passwordModifiedSuccessfully: 'Password modified successfully !',
    passwordReset: 'Password Reset',
    passwordTooShort: 'Password must be at least 6 characters long',
    pdfFile: 'PDF file',
    renameDocument: 'Rename document',
    requiredField: 'Required field',
    reset: 'Reset',
    resetPasswordExplanation: 'Enter your email to reset your password. If you have an account, you will receive an email to redefine your password.',
    role: 'Role',
    roleCreatedSuccessfully: 'Role created successfully',
    roleDeletedSuccessfully: 'Role deleted successfully',
    roleEditedSuccessfully: 'Role edited successfully',
    roleNameHint: 'Should begin with ROLE_  (ex: ROLE_MARKETING)',
    roles: 'Roles',
    ROLE_COMMERCIAL: 'Salesman',
    ROLE_COMMUNICATION: 'Communication',
    ROLE_EXPORT: 'Export',
    ROLE_TOURISM: 'Tourism',
    ROLE_VRP: 'VRP',
    salesman: 'Salesman',
    save: 'Save',
    search: 'Search',
    someUsers: 'Some users',
    spreadsheetFile: 'Spreadsheet file',
    submit: 'Submit',
    textFile: 'Text file',
    tourism: 'Tourism',
    typeNewPassword: 'Enter your new password. Then, you\'ll be directly logged in.',
    uploadingFiles: 'Uploading files',
    userAddedSuccessfully: 'User added successfully',
    userDeletedSuccessfully: 'User deleted successfully',
    userEditedSuccessfully: 'User edited successfully',
    usernameOrEmail: 'Username or email',
    users: 'Users',
    usersLoading: 'Users loading...',
    videoFile: 'Video file',
    views: 'Views',
    yes: 'Yes'
};

export default en;