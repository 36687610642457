<template>
  <div
    class="main-menu"
  >
    <v-list style="flex: 1;">
      <template v-for="(category, index) in categories">
        <v-list-group
          v-if="!category.level"
          :key="index"
          no-action
          active-class="grey-bg"
          :append-icon="category.children.length ? 'mdi-chevron-down' : ''"
          @click="() => { $emit('categoryClick', category); $store.commit('setDrawer', false)}"
        >
          <v-list-item
            v-if="category.children.length"
            class="pl-6"
            @click="() => { $emit('seeAll', category); $store.commit('setDrawer', false)}"
          >
            {{ $t('all') }}
          </v-list-item>
          <template #activator>
            <v-list-item-title
              style="white-space: normal;"
              @click="isMobile ? () => { $emit('categoryClick', category); $store.commit('setDrawer', false);} : () => $set($parent, 'activeCategory', category)"
            >
              <strong>{{ $i18n.locale === 'fr' ? category.labelFR : category.labelEN }}</strong>
              <v-chip
                v-if="category.documents.length && category.documents.some(r=> documentsUnread.map(doc => doc._id).indexOf(r) >= 0)"
                label
                x-small
                class="ml-2 mb-1"
                color="primary"
              >
                NEW
              </v-chip>
            </v-list-item-title>
          </template>
          <v-list-item
            v-for="(subCat, idx) in category.children"
            :key="'subcat-'+idx"
            :class="`pl-${subCat.level*6}`"
            @click="$emit('categoryClick', subCat)"
          >
            <span :class="subCat.level === 1 && category.children.find(cat => cat.level > subCat.level) ? 'font-weight-bold' : ''">
              {{ $i18n.locale === 'fr' ? subCat.labelFR : subCat.labelEN }}
            </span>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';

export default {
    props: {
        categories: {
            type: Array,
            required: true,
        },
        documentsUnread: {
          type: Array,
          default: () => []
        }
    },
    data(){
      return {
        isMobile
      };
    },
    methods: {
      setCategory(category){
        this.$parent.$parent.$parent.activeCategory = category;
        this.$emit('categoryClick');
        this.$store.commit('setDrawer', false);
      }
    }
};
</script>