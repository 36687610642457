<template>
  <div
    class="row documents-list-list"
  >
    <template v-if="activeCategory.children">
      <!-- Start Folders -->
      <v-col
        v-for="(subCategory, idx) in activeCategory.children.filter(cat => cat.level === 1)"
        :key="`folder-${idx}`"
        cols="12"
        class="documents-list-line"
        @click="$parent.loadDocuments(subCategory)"
      >
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-row"
          >
            <div
              class="text-truncate d-flex align-center"
              style="flex:1;"
            >
              <v-icon>mdi-folder</v-icon>
              <div
                class="text-truncate pl-2"
              >
                {{ $i18n.locale === 'fr' ? subCategory.labelFR : subCategory.labelEN }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <!-- End Folders -->
    </template>
    <!-- Start General Catalog -->
    <v-col
      v-if="activeCategory.hasCatalog"
      cols="12"
      class="documents-list-line"
      @click="clickedOn({catalogItem: true})"
    >
      <v-row>
        <v-col
          cols="12"
          class="text-truncate"
        >
          <v-icon>mdi-web</v-icon>
          <span class="black--text ml-2">{{ $i18n.locale === 'en' ? 'General Catalog' : 'Catalogue Général' }}</span>
        </v-col>
      </v-row>
    </v-col>
    <!-- End General Catalog -->
    <!-- Start Documents -->
    <v-col
      v-for="(document, idx) in documents.filter(doc => doc.locales.includes($i18n.locale))"
      :key="idx"
      cols="12"
      class="documents-list-line"
      @click="clickedOn(document)"
    >
      <v-row> 
        <v-col
          cols="7"
          class="text-truncate"
        >
          <v-icon v-if="document.contentType.includes('image')">
            mdi-image
          </v-icon>
          <v-icon
            v-else-if="document.path.includes('pdf')"
            style="color: red;"
          >
            mdi-file-pdf-box
          </v-icon>
          <v-icon
            v-else-if="document.contentType.includes('spreadsheet')"
            style="color: green;"
          >
            file-excel
          </v-icon>
          <v-icon
            v-else-if="document.contentType.includes('open')"
            style="color: blue;"
          >
            mdi-file-word-box
          </v-icon>
          <v-icon
            v-else
            style="color: grey;"
          >
            mdi-file
          </v-icon>
          <v-chip
            v-if="documentsUnread && documentsUnread.find(monr => monr._id === document._id && monr.read === undefined)"
            label
            x-small
            class="ml-2 mb-1"
            color="primary"
          >
            NEW
          </v-chip>
          <span class="black--text ml-2">{{ $i18n.locale === 'en' ? document.nameEN || document.name : document.name }}</span>
        </v-col>
        <v-col cols="4">
          {{ translateMimeType(document.contentType) }}
        </v-col>
        <v-col
          cols="1"
          class="d-flex justify-end"
        >
          <v-btn
            v-if="isDownloadable"
            :loading="downloadingId === document._id"
            small
            icon
            @click.stop="downloadDocument(document)"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import moment from 'moment';
/* eslint-disable no-undef */
export default {
    /**
     * @var {object} activeCategory category being currently browsed
     * @var {array<object>} documents documents of the activeCategory
     */
    props: {
        activeCategory: {
            type: Object,
            default: null
        },
        documents: {
            type: Array,
            default: null,
        },
        documentsUnread: {
          type: Array,
          default: () => []
        }
    },
    /**
     * @var {boolean} showMenu toggles right click menu
     * @var {number} x x position of the cursor used to display document right click menu
     * @var {number} y y position of the cursor used to display document right click menu
     * @var {number} downloadingId id of the document being currently downloaded, to display spinner
     * @var {number} folderX same as x but for category right click menu
     * @var {number} folderY same as y but for category right click menu
     * @var {object} denyCategory category selected to set users authorizations 
     */
    data(){
      return {
        moment,
        downloadingId: null
      };
    },  
    computed: {
      /**
       * @var {string} apiUrl base url of api
       */
       apiUrl(){
          return process.env.VUE_APP_BASE_API_PUBLIC_URL;
      },
      isDownloadable(){
        if(this.activeCategory.isDownloadable && this.$store.state.user.role.permissions.extranet.categoriesPermissions.find(perm => perm.category === this.activeCategory._id).authorizations.download){
          return true;
        }
        return false;
      }
    }, 
    methods: {
        /**
         * @returns {number} index of document in data documents
         * @param {object} doc document object 
         * Returns index of document in data documents array
         */
        getDocumentIdx(doc){
            return this.documents.findIndex(document => document._id === doc._id);
        },
        /**
         * @param {object} doc document to download
         * Downloads the document and posts download history if successful 
         */
        async downloadDocument(doc){
          this.downloadingId = doc._id;
          const res = await this.axios.get(
            `${this.apiUrl}${doc.path}`,
            {
              responseType: 'blob'
            }
          );
          if(res?.data){
            const blob = new Blob([res.data], { type: res.data.type });
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.setAttribute('download', this.$i18n.locale === 'en' ? doc.nameEN || doc.name : doc.name); 
            a.click();
            URL.revokeObjectURL(a.href);
            this.$store.getters.api.post(
              'histories',
              {
                user: this.$store.state.user._id,
                type: 'download',
                document: doc._id,
              },
            );
          }
          this.downloadingId = null;
        },
        /**
         * @returns {string}
         * @param {string} mimeType
         * Returns translated human readable file type 
         */
        translateMimeType(mimeType){
          if(mimeType.includes('application')){
            if(mimeType.includes('pdf')){
              return this.$t('pdfFile');
            }
            if(mimeType.includes('vnd')){
              return this.$t('spreadsheetFile');
            }
            if(mimeType.includes){
              return this.$t('docFile');
            }
          }
          if(mimeType.includes('text')){
            return this.$t('textFile');
          }
          if(mimeType.includes('audio')){
            return this.$t('audioFile');
          }
          if(mimeType.includes('video')){
            return this.$t('videoFile');
          }
          if(mimeType.includes('image')){
            return `${this.$t('file')} ${mimeType.split('/')[1].toUpperCase()}`;
          }
        },
        /**
         * @param {object} document document being clicked
         * If document is the catalog item, redirect to the catalog website
         * Else, set document as selectedDocument and display it in doc viewer 
         */
        clickedOn(document){
          if(document.catalogItem !== true){
            this.$set(this.$parent, 'selectedDocument', this.getDocumentIdx(document));
          } else {
            window.location.replace('https://catalogue.spiriterie.com');
          }
        }
    }
};
</script>