<template>
  <div class="d-flex">
    <v-icon
      class="mr-2"
    >
      mdi-format-list-bulletted-square
    </v-icon>
    {{ $t('extranet') }}
    <template v-for="(category, index) in breadcrumb">
      <div
        :key="index"
        class="d-flex"
      >
        <span class="mx-2">
          <v-icon>mdi-chevron-right</v-icon>
        </span>
        <span
          :class="index+1 < breadcrumb.length ? 'cursor-pointer' : 'bold'"
          @click="$parent.loadDocuments(category)"
        >
          {{
            $i18n.locale === 'fr' ?
              category.labelFR :
              category.labelEN
          }} 
        </span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  /**
   * @var {object} activeCategory category currently browsed
   * @var {array<object>} categories available categories
   */
    props: {
        activeCategory: {
            type: Object,
            default: null,
        },
        categories: {
            type: Array,
            required: true,
        }
    },
    computed: {
      breadcrumb(){
        let breadcrumb = [this.activeCategory];
        let buildBreadcrumb = (category) => {
          if(category?.parent){
            let parent = this.$parent.categories.find(cat => cat._id === category.parent._id);
            breadcrumb.unshift(parent);
            buildBreadcrumb(parent);
          }
        };
        buildBreadcrumb(this.activeCategory);
        return breadcrumb;
      }
    }
};
</script>