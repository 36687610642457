<template>
  <div
    class="row documents-mosaic-list"
  >
    <!-- Start Folders -->
    <template v-if="activeCategory.children">
      <v-col
        v-for="(subCategory, idx) in activeCategory.children.filter(cat => cat.level === 1)"
        :key="`folder-${idx}`"
        cols="12"
        md="2"
      >
        <v-card
          elevation="0"
          class="mosaic-card d-flex align-center"
          style="height: 100%;"
          @click="$parent.loadDocuments(subCategory)"
        >
          <v-card-text
            class="d-flex align-center"
          >
            <div>
              <v-icon
                x-large
                class="primary--color"
              >
                mdi-folder
              </v-icon>
            </div>
            <div
              class="text-truncate pl-2 black--text"
              style="flex: 1"
            >
              {{ $i18n.locale === 'fr' ? subCategory.labelFR : subCategory.labelEN }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
    <!-- End Folders -->
    <!-- Start General Catalog -->
    <v-col
      v-if="activeCategory.hasCatalog"
      cols="12"
      md="2"
    >
      <v-card
        elevation="0"
        class="mosaic-card"
        @click="clickedOn({catalogItem: true})"
      >
        <v-card-text class="d-flex align-center">
          <div
            class="pt-1 d-flex justify-cenrer align-start"
            style="width: 50px"
          >
            <v-img
              :src="require('@/assets/images/catalogThumbnail.jpg')"
              contain
              position="top"
            />
          </div>
          <div
            class="text-truncate pl-2 pr-1"
            style="flex:1"
          >
            <span class="black--text">{{ $i18n.locale === 'en' ? 'General Catalog' : 'Catalogue Général' }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- End General Catalog -->
    <!-- Start Documents -->
    <v-col
      v-for="(document, idx) in documents.filter(doc => doc.locales.includes($i18n.locale))"
      :key="idx"
      cols="12"
      md="2"
    >
      <v-card
        elevation="0"
        class="mosaic-card"
        @click="clickedOn(document)"
      >
        <v-card-text
          class="d-flex align-center"
        >
          <div
            class="pt-1 d-flex justify-center align-start"
            style="width: 50px"
          >
            <v-img
              v-if="['image', 'vnd'].some(format => document.contentType.includes(format))"
              :src="`${apiUrl}${document.path}`"
              contain
              position="top"
            />
            <v-icon
              v-else-if="document.contentType.includes('pdf')"
              style="color: red"
            >
              mdi-file-pdf-box
            </v-icon>
            <v-icon
              v-else-if="document.path.includes('spreadsheet')"
              style="color: blue;"
            >
              mdi-file-excel
            </v-icon>
            <v-icon
              v-else
              style="color: grey;"
            >
              mdi-file
            </v-icon>
          </div>
          <div
            class="text-truncate pl-2 pr-1"
            style="flex:1"
          >
            <v-chip
              v-if="documentsUnread && documentsUnread.find(monr => monr.id === document.id && monr.read === undefined)"
              label
              x-small
              class="mr-1"
              color="primary"
            >
              NEW
            </v-chip>
            <span class="black--text">{{ $i18n.locale === 'en' ? document.nameEN || document.name : document.name }}</span><br>
            {{ translateMimeType(document.contentType) }}<br>
          </div>
          <v-btn
            v-if="isDownloadable"
            small
            icon
            :loading="downloadingId === document._id"
            @click.stop="downloadDocument(document)"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>

/* eslint-disable no-undef */
export default {
    /**
     * @var {object} activeCategory category being currently browsed
     * @var {array<object>} documents documents of the activeCategory
     */
    props: {
        activeCategory: {
            type: Object,
            default: null
        },
        documents: {
            type: Array,
            default: null,
        },
        documentsUnread: {
          type: Array,
          default: () => []
        }
    },
    /**
     * @var {number} downloadingId id of the document being currently downloaded, to display spinner
     */
    data(){
      return {
        downloadingId: null,
      };
    },
    computed: {
      /**
       * @var {string} apiUrl base url of api
       */
      apiUrl(){
          return process.env.VUE_APP_BASE_API_PUBLIC_URL;
      },
      isDownloadable(){
        if(this.activeCategory.isDownloadable && this.$store.state.user.role.permissions.extranet.categoriesPermissions.find(perm => perm.category === this.activeCategory._id).authorizations.download){
          return true;
        }
        return false;
      }
    },
    methods: {
        /**
         * @returns {number} index of document in data documents
         * @param {object} doc document object 
         * Returns index of document in data documents array
         */
        getDocumentIdx(doc){
            return this.documents.findIndex(document => document === doc);
        },
        /**
         * @param {object} doc document to download
         * Downloads the document and posts download history if successful 
         */
        async downloadDocument(doc){
          this.downloadingId = doc._id;
          const res = await this.axios.get(
            `${this.apiUrl}${doc.path}`,
            {
              responseType: 'blob'
            }
          );
          if(res?.data){
            const blob = new Blob([res.data], { type: res.data.type });
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.setAttribute('download', this.$i18n.locale === 'en' ? doc.nameEN || doc.name : doc.name); 
            a.click();
            URL.revokeObjectURL(a.href);
            this.$store.getters.api.post(
              'histories',
              {
                user: this.$store.state.user._id,
                type: 'download',
                document: doc._id
              },
            );
          }
          this.downloadingId = null;
        },
        /**
         * @returns {string}
         * @param {string} mimeType
         * Returns translated human readable file type 
         */
        translateMimeType(mimeType){
          if(mimeType.includes('application')){
            if(mimeType.includes('pdf')){
              return this.$t('pdfFile');
            }
            if(mimeType.includes('vnd')){
              return this.$t('spreadsheetFile');
            }
            if(mimeType.includes){
              return this.$t('docFile');
            }
          }
          if(mimeType.includes('text')){
            return this.$t('textFile');
          }
          if(mimeType.includes('audio')){
            return this.$t('audioFile');
          }
          if(mimeType.includes('video')){
            return this.$t('videoFile');
          }
          if(mimeType.includes('image')){
            return `${this.$t('file')} ${mimeType.split('/')[1].toUpperCase()}`;
          }
        },
        /**
         * @param {object} document document being clicked
         * If document is the catalog item, redirect to the catalog website
         * Else, set document as selectedDocument and display it in doc viewer 
         */
        clickedOn(document){
          if(document.catalogItem !== true){
            this.$set(this.$parent, 'selectedDocument', this.getDocumentIdx(document));
          } else {
            window.location.replace('https://catalogue.spiriterie.com');
          }
        }
    }
};
</script>