<template>
  <div
    class="d-flex"
    style="height: 100%"
  >
    <v-row no-gutters>
      <v-col
        cols="6"
        class="primary d-flex flex-column py-8 login-left-side"
        :class="slide ? 'slide-left' : ''"
      >
        <div
          class="mx-auto"
          style="width: 60%"
        >
          <h2 class="text-h2 white--text">
            Extranet
          </h2>
          <h5 class="text-h5 white--text mt-5">
            La Spiriterie Française
          </h5>
        </div>
        <div
          style="position: relative;"
          class="flex d-flex align-center justify-center"
        >
          <div
            class="login-circle"
            :class="loggedIn ? 'rotate' : ''"
          />
        </div>
      </v-col>
      <v-col
        class="d-flex align-center justify-center py-8 login-right-side white"
        :class="slide ? 'slide-right' : ''"
        cols="6"
      >
        <div style="width: 70%">
          <h5 class="text-h4">
            Connexion
          </h5>
          <small>Bienvenue ! Connectez vous avec votre email.</small>
          <v-form
            class="my-9"
            @submit="login"
          >
            <v-text-field
              v-model="username"
              outlined
              prepend-inner-icon="mdi-account"
              label="Login"
              placeholder="Nom d'utilisateur ou adresse email"
            />
            <v-text-field
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              outlined
              prepend-inner-icon="mdi-lock"
              label="Mot de passe"
            >
              <template #append>
                <v-icon
                  @mousedown="showPassword = true"
                  @mouseup="showPassword = false"
                  @mouseleave="showPassword = false"
                >
                  mdi-eye
                </v-icon>
              </template>
            </v-text-field>
            <div class="text-right">
              <router-link
                link
                to="/reset-password"
              >
                {{ $t('forgottenPassword') }}
              </router-link>
            </div>
            <v-btn
              class="w-100 mt-9"
              style="width: 100%"
              color="secondary"
              @click="login"
            >
              CONNEXION
            </v-btn>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
    data(){
        return {
            username: null,
            password: null,
            showPassword: false,
            loggedIn: false,
            slide: false
        };
    },
    created(){
      localStorage.removeItem('LSF_EXTRANET_ACCESS_TOKEN');
      localStorage.removeItem('LSF_EXTRANET_REFRESH_TOKEN');
    },
    methods: {
        login(){
            this.axios.post('auth/login', {username: this.username, password: this.password}).then(res => {
                localStorage.setItem('LSF_EXTRANET_ACCESS_TOKEN', res.data.accessToken);
                localStorage.setItem('LSF_EXTRANET_REFRESH_TOKEN', res.data.refreshToken);
                this.axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
                this.loggedIn = true;
                this.axios.post('extranet-users/current').then(res => {
                    this.$store.commit('setUser', res.data);
                    window.setTimeout(() => {
                        this.slide = true;
                        window.setTimeout(() => {
                            this.$router.push('/');
                        }, 500);
                    }, 1000);
                }).catch(err => {
                    console.log(err.msg);
                });
            }).catch(err => console.log(err));
        }
    }
};
</script>