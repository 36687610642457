<template>
  <div
    class="d-flex"
    style="height: 100%"
  >
    <v-row no-gutters>
      <v-col
        class="d-flex align-center justify-center py-8 login-right-side white"
        cols="6"
      >
        <div style="width: 70%">
          <h5 class="text-h4">
            {{ token && userId ? $t('passwordReset') : $t('forgottenPassword') }}
          </h5>
          <small>{{ token && userId ? $t('typeNewPassword') : $t('resetPasswordExplanation') }}</small>
          <v-form
            class="my-9"
            @submit="token && userId ? update : reset"
          >
            <v-text-field
              v-if="!token && !userId"
              v-model="username"
              outlined
              prepend-inner-icon="mdi-account"
              :label="$t('usernameOrEmail')"
              :placeholder="$t('usernameOrEmail')"
            />
            <v-text-field
              v-if="token && userId"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              outlined
              prepend-inner-icon="mdi-lock"
              :label="$t('password')"
              :error="errors[0] !== false"
              :error-messages="errors[0]"
            >
              <template #append>
                <v-icon
                  @mousedown="showPassword = true"
                  @mouseup="showPassword = false"
                  @mouseleave="showPassword = false"
                >
                  mdi-eye
                </v-icon>
              </template>
            </v-text-field>
            <v-text-field
              v-if="token && userId"
              v-model="passwordRepeat"
              :type="showPasswordRepeat ? 'text' : 'password'"
              outlined
              prepend-inner-icon="mdi-lock"
              :label="$t('passwordRepeat')"
              :error="errors[1] !== false"
              :error-messages="errors[1]"
            >
              <template #append>
                <v-icon
                  @mousedown="showPasswordRepeat = true"
                  @mouseup="showPasswordRepeat = false"
                  @mouseleave="showPasswordRepeat = false"
                >
                  mdi-eye
                </v-icon>
              </template>
            </v-text-field>
            <div class="text-right">
              <router-link
                link
                to="/login"
              >
                {{ $t('login') }}
              </router-link>
            </div>
            <v-btn
              class="w-100 mt-9"
              style="width: 100%"
              color="secondary"
              :loading="loading"
              @click="token && userId ? update() : reset()"
            >
              {{ token && userId ? $t('submit') : $t('reset') }}
            </v-btn>
          </v-form>
        </div>
      </v-col>
      <v-col
        cols="6"
        class="primary d-flex flex-column py-8 login-left-side"
      >
        <div
          class="mx-auto"
          style="width: 60%"
        >
          <h2 class="text-h2 white--text">
            Extranet
          </h2>
          <h5 class="text-h5 white--text mt-5">
            La Spiriterie Française
          </h5>
        </div>
        <div
          style="position: relative;"
          class="flex d-flex align-center justify-center"
        >
          <div
            class="login-circle"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default{
    /**
     * @var {string} password
     * New password for user
     * 
     * @var {string} passwordRepeat
     * Password checking (must equal password)
     * 
     * @var {loading}
     * To display a spinner when sending request
     * 
     * @var {boolean} showPassword
     * @var {boolean} showPasswordRepeat
     * Toggles password fields visibility
     * 
     * @var {string} token
     * Reset authorization token received by email
     */
    data(){
        return {
          username: null,
          password: '',
          passwordRepeat: '',
          loading: false,
          showPassword: false,
          showPasswordRepeat: false,
          token: null,
          userId: null,
          errors: [false, false]
        };
    },
    /**
     * If user is already logged in, we redirect to main view
     * Is url query contains "token", we set the data and erase it from url
     * @TODO display error toast when token is not in query or display a token field
     */
    created(){
      if(this.$route.query.token || this.$route.query.id){
        this.token = this.$route.query.token;
        this.userId = this.$route.query.id;
        this.$router.replace({
          ...this.$router.currentRoute,
          query: null
        });
      }
    },
    methods: {
      /**
       * Submit password reset
       */
      reset(){
        this.loading = true;
        this.axios.post('auth/reset-token', {username: this.username}).then(() => {
          this.loading = false;
          this.$router.push('/login');
          this.$store.dispatch('setSnackbar', {
              show: true,
              type: 'success',
              text: this.$i18n.locale === 'fr' ? 'Demande de réinitialisation de mot de passe effectuée' : 'Password reset request sent !'  
          });
        });
      },
      update(){
        let hasError = false;
        this.errors = [false, false];
        if(!this.password.trim()){
          this.errors[0] = 'Mot de passe obligatoire';
          hasError = true;
        }
        if(!this.passwordRepeat.trim()){
          this.errors[1] = 'Mot de passe obligatoire';
          hasError = true;
        }
        if(this.password && this.passwordRepeat && this.password.trim() !== this.passwordRepeat.trim()){
          this.errors[1] = 'Les mots de passe doivent correspondre';
          hasError = true;
        }
        if(hasError){
          return;
        }
        this.errors = [false, false];
        this.loading = true;
        this.axios.post('auth/password-reset', {token: this.token, userId: this.userId, password: this.password.trim()}).then(res => {
          localStorage.setItem('LSF_EXTRANET_ACCESS_TOKEN', res.data.accessToken);
          localStorage.setItem('LSF_EXTRANET_REFRESH_TOKEN', res.data.refreshToken);
          this.axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
          this.loggedIn = true;
          this.axios.post('extranet-users/current').then(res => {
            this.$store.commit('setUser', res.data);
            this.$router.push('/');
            this.$store.dispatch('setSnackbar', {
                  show: true,
                  type: 'success',
                  text: this.$i18n.locale === 'fr' ? 'Mot de passe réinitialisé' : 'Password reset'  
              });
        }).finally(() => { this.loading = false;});
      });
    }
    },
};
</script>