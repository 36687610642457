const fr = {
    add: 'Ajouter',
    addCategory: 'Ajouter une catégorie',
    addRole: 'Ajouter un rôle',
    addUser: 'Ajouter un utilisateur',
    administrator: 'Administrateur',
    admins: 'Administrateurs',
    all: 'Tout',
    allowedCategories: 'Catégories autorisées',
    allUsers: 'Tous les utilisateurs',
    audioFile: 'Fichier audio',
    canDownloadEverything: 'Peut télécharger tous les fichiers',
    categoryAuthorizationUpdatedSuccessfully: 'Autorisations de la catégorie modifiées',
    categoryDeletedSuccessfully: 'Catégorie supprimée avec succès',
    categoryEditedSuccessfully: 'Catégorie éditée avec succès',
    categoryPostedSuccessfully: 'Catégorie ajoutée avec succès',
    categories: 'Catégories',
    categoriesLoading: 'Chargement des catégories...',
    clickToEdit: 'Cliquez sur un élement pour le modifier',
    close: 'Fermer',
    communication: 'Communication',
    country: 'Pays',
    countryNotEmpty: 'Le pays est requis',
    deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer l\'utilisateur',
    deleteUser: 'Supprimer un utilisateur',
    denyAccess: 'Restreindre l\'accès à certains utilisateurs',
    department: 'Département',
    display: 'Affichage',
    docFile: 'Document Word',
    documentAuthorizationUpdatedSuccessfully: 'Autorisations du document modifiées',
    documentDeleted: 'Document supprimé avec succès',
    documentName: 'Nom du document',
    documentRenamedSuccessfully: 'Document renommé avec succès',
    dndExplanation: 'Glissez et déposez les éléments pour réordonner les catégories et sous-catégories',
    downloads: 'Téléchargements',
    editCategory: 'Éditer une catégorie',
    editRole: 'Éditer un rôle',
    editUser: 'Éditer un utilisateur',
    email: 'Adresse email',
    emailInvalid: 'Adresse email invalide',
    emailNotEmpty: 'Adresse email requise',
    emailSent: 'Email envoyé',
    english: 'Anglais',
    englishTitle: 'Titre Anglais',
    errorMessage: 'Une erreur est survenue',
    export: 'Export',
    exportStats: 'Export statistiques',
    exportVerb: 'Exporter',
    extranet: 'Extranet',
    fieldNotEmpty: 'Le champs est requis',
    file: 'Fichier',
    fileUploaded: 'Fichier ajouté avec succès',
    filterByRole: 'Filtrer par rôle',
    firstName: 'Prénom',
    firstNameNotEmpty: 'Le prénom est requis',
    footerDisclaimer: 'L\'accès a cet espace vous a été personnellement octroyé, nous vous recommandons de ne pas partager vos accès à une tierce personne.',
    forgottenPassword: 'Mot de passe oublié',
    french: 'Français',
    frenchTitle: 'Titre Français',
    history: 'Historique',
    isDownloadable: 'Autoriser le téléchargement des fichiers',
    labelFr: 'Nom français',
    labelEn: 'Nom anglais',
    lastLogin: 'Dernière connexion',
    lastName: 'Nom',
    lastNameNotEmpty: 'Le nom est requis',
    loading: 'Chargement...',
    locale: 'Langue',
    localeNotEmpty: 'La langue est requise',
    login: 'Connexion',
    logins: 'Connexions',
    loginHistory: 'Historique de connexions',
    lastLoginsExplanation: 'Voici les 10 dernières connexions pour l\'utilisateur',
    lastDownloadsExplanation: 'Voici les 10 derniers fichiers téléchargés par l\'utilisateur',
    lastViewsExplanation: 'Voici les 10 derniers fichiers visionnés par l\'utilisateur',
    logout: 'Déconnexion',
    name: 'Nom',
    nameNotEmpty: 'Le nom ne peut pas être vide.',
    no: 'Non',
    parentCategory: 'Catégorie parente',
    password: 'Mot de passe',
    passwordRepeat:' Répétez le mot de passe',
    passwordNotEmpty: 'Le mot de passe est requis',
    passwordsDontMatch: 'Les mots de passe doivent correspondre',
    passwordReset: 'Réinitialisation du mot de passe',
    passwordModifiedSuccessfully: 'Mot de passe modifié avec succès !',
    passwordTooShort: 'Le mot de passe doit contenir au moins 6 caractères',
    pdfFile: 'Fichier PDF',
    renameDocument: 'Renommer le document',
    requiredField: 'Champ requis',
    reset: 'Réinitialiser',
    resetPasswordExplanation: 'Entrez votre adresse email pour réinitialiser votre mot de passe. Si vous avez un compte, vous recevrez un email pour le redéfinir.',
    role: 'Rôle',
    roleCreatedSuccessfully: 'Rôle créé avec succès',
    roleDeletedSuccessfully: 'Rôle supprimé avec succès',
    roleEditedSuccessfully: 'Rôle édité avec succès',
    roleNameHint: 'Devrait commencer par ROLE_  (ex: ROLE_MARKETING)',
    roles: 'Rôles',
    ROLE_COMMERCIAL: 'Commercial',
    ROLE_COMMUNICATION: 'Communication',
    ROLE_EXPORT: 'Export',
    ROLE_TOURISM: 'Tourisme',
    ROLE_VRP: 'VRP',
    salesman: 'Commercial',
    save: 'Sauvegarder',
    search: 'Rechercher',
    someUsers: 'Certains utilisateurs',
    spreadsheetFile: 'Feuille de calcul',
    submit: 'Envoyer',
    textFile: 'Fichier texte',
    tourism: 'Tourisme',
    typeNewPassword: 'Entrez votre nouveau mot de passe. Vous serez ensuite directement connecté à l\'application',
    uploadingFiles: 'Upload des fichiers',
    userAddedSuccessfully: 'Utilisateur ajouté avec succès',
    usernameOrEmail: 'Nom d\'utilisateur ou email',
    userDeletedSuccessfully: 'Utilisateur supprimé avec succès',
    userEditedSuccessfully: 'Utilisateur modifié avec succès',
    users: 'Utilisateurs',
    usersLoading: 'Chargement des utilisateurs...',
    videoFile: 'Fichier vidéo',
    views: 'Vues',
    yes: 'Oui'
};

export default fr;