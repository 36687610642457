// To enhance app, search for @TODO

import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import Toasted from 'vue-toasted';
import { isMobile, isTablet, isMobileOnly } from 'mobile-device-detect';
import _axios from './plugins/axios';
import VueAxios from 'vue-axios';

import fr from './translations/fr';
import countriesFr from './translations/countriesFr';
import errorsFr from './translations/errorsFr';

import en from './translations/en';
import countriesEn from './translations/countriesEn';
import errorsEn from './translations/errorsEn';

import './sass/style.sass';

Vue.config.productionTip = false;

Vue.use(VueI18n);
const messages = {
  fr: {...fr, ...countriesFr, ...errorsFr},
  en: {...en, countriesEn, ...errorsEn},
};

const i18n = new VueI18n({
  locale: store.getters.locale,
  messages
});

Vue.use(VueAxios, _axios);

Vue.use(Toasted, {
  position: 'top-right',
});

new Vue({
  vuetify,
  router,
  store,
  i18n,
  isMobile,
  isMobileOnly,
  isTablet,
  render: h => h(App)
}).$mount('#app');
