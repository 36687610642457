<template>
  <div
    class="row documents-list"
  >
    <!-- Start Folders -->
    <template v-if="activeCategory.children && !activeCategory.parent">
      <v-col
        v-for="(subCategory, idx) in activeCategory.children.filter(cat => cat.level === 1)"
        :key="`folder-${idx}`"
        cols="12"
        md="2"
      >
        <v-tooltip
          bottom
          nudge-top="170px"
        >
          <template
            #activator="{on}"
          >
            <v-card
              v-on="on"
              @click="$parent.loadDocuments(subCategory)"
            >
              <v-card-title
                style="height: 200px"
                class="d-flex align-center justify-center"
              >
                <v-icon
                  x-large
                  style="color: grey;"
                >
                  mdi-folder
                </v-icon>
              </v-card-title>
              <v-card-text
                class="primary-bg white--text d-flex align-center pb-0"
                style="height: 50px;"
              >
                <div class="text-truncate">
                  {{ $i18n.locale === 'fr' ? subCategory.labelFR : subCategory.labelEN }}
                </div>
              </v-card-text>
            </v-card>
          </template>
          {{ $i18n.locale === 'fr' ? subCategory.labelFR : subCategory.labelEN }}
        </v-tooltip>
      </v-col>
    </template>
    <template v-if="activeCategory.parent && activeCategory.level === 1 && $parent.categories.find(cat => cat._id === activeCategory.parent._id).children.find(child => child.level === 2)">
      <v-col
        v-for="(subCategory, idx) in $parent.categories.find(cat => cat._id === activeCategory.parent._id).children.filter(cat => cat.level === 2 && cat.parent._id === activeCategory._id)"
        :key="`folder-${idx}`"
        cols="12"
        md="2"
      >
        <v-tooltip
          bottom
          nudge-top="170px"
        >
          <template
            #activator="{on}"
          >
            <v-card
              v-on="on"
              @click="$parent.loadDocuments(subCategory)"
            >
              <v-card-title
                style="height: 200px"
                class="d-flex align-center justify-center"
              >
                <v-icon
                  x-large
                  style="color: grey;"
                >
                  mdi-folder
                </v-icon>
              </v-card-title>
              <v-card-text
                class="primary-bg white--text d-flex align-center pb-0"
                style="height: 50px;"
              >
                <div class="text-truncate">
                  {{ $i18n.locale === 'fr' ? subCategory.labelFR : subCategory.labelEN }}
                </div>
              </v-card-text>
            </v-card>
          </template>
          {{ $i18n.locale === 'fr' ? subCategory.labelFR : subCategory.labelEN }}
        </v-tooltip>
      </v-col>
    </template>
    <!-- End Folders -->
    <!-- Start Catalog -->
    <!-- End Catalog -->
    <v-col
      v-if="activeCategory.hasCatalog"
      cols="12"
      md="2"
    >
      <v-card @click="clickedOn({catalogItem: true})">
        <v-card-title
          style="height: 200px"
          class="d-flex align-center justify-center"
        >
          <v-img
            :src="require('@/assets/images/catalogThumbnail.jpg')"
            contain
            style="height: 160px"
          />
        </v-card-title>
        <v-card-text
          class="grey-bg d-flex align-center pb-0"
          style="height: 50px;"
        >
          <div class="text-truncate">
            {{ $i18n.locale === 'en' ? 'General Catalog' : 'Catalogue Général' }}
          </div>
        </v-card-text>
      </v-card>  
    </v-col>
    <!-- Start Documents -->
    <v-col
      v-for="(document, idx) in documents.filter(doc => doc.locales.includes($i18n.locale))"
      :key="idx"
      cols="12"
      md="2"
    >
      <v-tooltip
        bottom
        nudge-top="170px"
      >
        <template
          #activator="{on}"
        >
          <v-card
            v-on="on"
            @click="clickedOn(document)"
          >
            <v-card-title
              style="height: 200px"
              class="d-flex align-center justify-center"
            >
              <v-img
                v-if="['image', 'vnd'].some(format => document.contentType.includes(format))"
                :src="`${apiUrl}${document.path}`"
                contain
                style="height: 160px;"
              />
              <div
                v-else-if="document.contentType.includes('pdf')"
                class="overflow-hidden"
              >
                <embed
                  style="width: calc(100% + 17px)!important;"
                  type="application/pdf"
                  :src="`${apiUrl}${document.path}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`"
                  @click.native="clickedOn(document)"
                >
              </div>
              <v-icon
                v-else-if="document.contentType.includes('spreadsheet')"
                icon="file-excel"
                style="color: blue;"
              >
                mdi-file-excel
              </v-icon>
              <v-icon
                v-else
                style="color: grey;"
              >
                mdi-file
              </v-icon>
              <v-chip
                v-if="documentsUnread && documentsUnread.find(monr => monr._id === document._id && monr.read === undefined)"
                label
                x-small
                class="ml-2 mb-1"
                style="position: absolute; top: 10px; right: 10px"
                color="primary"
              >
                NEW
              </v-chip>
            </v-card-title>
            <v-card-text
              class="grey-bg d-flex align-center pb-0"
              style="height: 50px;"
            >
              <div class="text-truncate">
                {{ $i18n.locale === 'en' ? document.nameEN || document.name : document.name }}
              </div>
              <v-spacer />
              <v-btn
                v-if="isDownloadable"
                icon
                small
                :loading="downloadingId === document._id"
                @click.stop="downloadDocument(document)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </template>
        {{ $i18n.locale === 'en' ? document.nameEN || document.name : document.name }}
      </v-tooltip>
    </v-col>
    <!-- End Documents -->
  </div>
</template>

<script>

/* eslint-disable no-undef */
export default {
    /**
     * @var {object} activeCategory category being currently browsed
     * @var {array<object>} documents documents of the activeCategory
     * @var {number} deletingFile id of the document being currently deleted (to display spinner)
     * @var {number} deletingFolder id of the category being currently deleted (to display spinner)
     */
    props: {
        activeCategory: {
            type: Object,
            default: null
        },
        documents: {
            type: Array,
            default: null,
        },
        documentsUnread: {
          type: Array,
          default: () => []
        }
    },
    /**
     * @var {number} downloadingId id of the document being currently downloaded, to display spinner
     */
    data(){
      return {
        downloadingId: null,
      };
    },
    computed: {
      /**
       * @var {string} apiUrl base url of api
       */
      apiUrl(){
          return process.env.VUE_APP_BASE_API_PUBLIC_URL;
      },
      isDownloadable(){
        if(this.activeCategory.isDownloadable && this.$store.state.user.role.permissions.extranet.categoriesPermissions.find(perm => perm.category === this.activeCategory._id).authorizations.download){
          return true;
        }
        return false;
      }
    },
    methods: {
      /**
         * @returns {number} index of document in data documents
         * @param {object} doc document object 
         * Returns index of document in data documents array
         */
         getDocumentIdx(doc){
            return this.documents.findIndex(document => document._id === doc._id);
        },
        /**
         * @param {object} doc document to download
         * Downloads the document and posts download history if successful 
         */
        async downloadDocument(doc){
          this.downloadingId = doc._id;
          const res = await this.axios.get(
            `${this.apiUrl}${doc.path}`,
            {
              responseType: 'blob'
            }
          );
          if(res?.data){
            const blob = new Blob([res.data], { type: res.data.type });
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.setAttribute('download', this.$i18n.locale === 'en' ? doc.nameEN || doc.name : doc.name); 
            a.click();
            URL.revokeObjectURL(a.href);
            this.axios.post(
              'extranet-histories',
              {
                user: this.$store.state.user._id,
                type: 'download',
                document: doc._id,
              },
            );
          }
          this.downloadingId = null;
        },
        /**
         * @param {object} document document being clicked
         * If document is the catalog item, redirect to the catalog website
         * Else, set document as selectedDocument and display it in doc viewer 
         */
        clickedOn(document){
          if(document.catalogItem){
            window.location.replace('https://catalogue.spiriterie.com');
          } else {
            this.$set(this.$parent, 'selectedDocument', this.getDocumentIdx(document));
            if(this.documentsUnread.find(monr => monr._id === document._id && monr.read === undefined)){
              let index = this.documentsUnread.findIndex(doc => doc._id === document._id);
              if(index > -1){
                this.$emit('documentRead', index);
                let user = {...this.$store.state.user};
                user.documentsUnread.splice(index,1);
                this.axios.put(`extranet-users/${this.$store.state.user._id}`, {documentsUnread: user.documentsUnread}).then(() => {
                  this.$nextTick();
                  this.$forceUpdate();
                });
              }
            }
          }
        },
    }
};
</script>