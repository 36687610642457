import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1e2f53',
                secondary: '#c86428',
                accent: '#285f78',
                anchor: '#c86428',
                success: '#43a035',
                error: '#e64b5f'
            },
        },
    },
});