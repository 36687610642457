import axios from "axios";
import store from '@/store';
import router from '@/router';

let config = {
  // eslint-disable-next-line
  baseURL: process.env.VUE_APP_BASE_API_URL
};

const _axios = axios.create(config);

let accessToken = localStorage.getItem('LSF_EXTRANET_ACCESS_TOKEN');
let refreshToken = localStorage.getItem('LSF_EXTRANET_REFRESH_TOKEN');

if(accessToken){
  _axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    if(!store.state.user && router.currentRoute.name !== 'login'){
      _axios.post('/extranet-users/current').then((res) => {
          store.commit('setUser', res.data);
      });
    }
    return response;
  },
  async function(error) {
    const originalRequest = error.config;
    // eslint-disable-next-line
    if(originalRequest.url !== `/auth/refreshToken` && error.response?.status === 401 && originalRequest._retry !== true) {
      originalRequest._retry = true;
      if(refreshToken && refreshToken !== "null" && refreshToken !== ""){
        _axios.defaults.headers.common['Authorization'] = `Bearer ${refreshToken}`;
        const response = await _axios.post('auth/refreshToken');
        if(response.data) {
          localStorage.setItem('LSF_EXTRANET_ACCESS_TOKEN', response.data.accessToken);
          originalRequest.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
          _axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
          const res = await _axios.post('extranet-users/current');
          if(res.data) {
            store.commit('setUser', res.data);
          }
          Promise.resolve(_axios(originalRequest));
        } else {
            refreshToken = null;
            localStorage.setItem('LSF_EXTRANET_REFRESH_TOKEN', null);
            localStorage.setItem('LSF_EXTRANET_REFRESH_TOKEN', null);
            if(router.currentRoute.name !== 'login'){
                router.push('/login');
            }
            Promise.resolve(_axios(originalRequest));
        }
      } else if(router.currentRoute.name !== 'login'){
        localStorage.setItem('LSF_EXTRANET_REFRESH_TOKEN', null);
        localStorage.setItem('LSF_EXTRANET_REFRESH_TOKEN', null);
        router.push('/login');
        Promise.resolve();
      } else {
        store.dispatch('setSnackbar', {
            show: true,
            type: 'error',
            text: error.response.status === 401 ? 'Identifiants invalides' : 'Une erreur est survenue.'
        });
        localStorage.removeItem('LSF_EXTRANET_ACCESS_TOKEN');
        localStorage.removeItem('LSF_EXTRANET_REFRESH_TOKEN');
        if(error.response.status === 401 && originalRequest.url !== '/users/current'){
          router.push('/login');
        }
        Promise.resolve();
      }
    } else {
      store.dispatch('setSnackbar', {
          show: true,
          type: 'error',
          text: error.response?.status === 401 ? 'Identifiants invalides' : 'Une erreur est survenue.'
      });
      localStorage.removeItem('LSF_EXTRANET_ACCESS_TOKEN');
      localStorage.removeItem('LSF_EXTRANET_REFRESH_TOKEN');
      if(error.response?.status === 401){
        router.push('/login');
      }
      return Promise.reject(error);
    }
  }
);

export default _axios;
