<template>
  <div
    class="h-100 d-flex"
    style="max-width: 100vw"
  >
    <!-- Navigation Drawer | App Navigation | Admin only on desktop, categories menu for mobile -->
    <navigation-drawer
      v-if="isMobile"
      :categories="sortedCategories"
    />
    <!-- End Navigation drawer -->
    <!-- Left menu with categories (desktop only) -->
    <main-menu
      v-if="categories.length && !isMobile"
      :categories="sortedCategories"
      :documents-unread="documentsUnread"
      @categoryClick="loadDocuments"
      @seeAll="seeAll"
    />
    <!-- End Left menu -->
    <!-- Documents container -->
    <v-container class="documents-container">
      <!-- Navigation & Filters top bar -->
      <v-row
        class="grey-bg w-100 breadcrumb"
      >
        <v-col
          cols="12"
          class="d-flex align-center"
        >
          <!-- Breadcrumb -->
          <breadcrumb
            v-if="activeCategory"
            :active-category="activeCategory"
            :categories="sortedCategories"
          />
          <!-- End Breadcrumb -->
          <v-spacer v-if="!isMobile" />
          <!-- Filters -->
          <div
            v-if="!isMobile"
            class="pr-2 d-flex"
          >
            <!-- Display mode toggles -->
            <span class="pt-2 pr-2">{{ $t('display') }}:</span>
            <v-btn
              icon
              @click="displayMode = 'card'"
            >
              <v-icon :class="displayMode !== 'card' ? 'grey--text' : ''">
                mdi-view-grid
              </v-icon>
            </v-btn>
            <v-btn
              icon
              class="mx-1"
              @click="displayMode = 'mosaic'"
            >
              <v-icon :class="displayMode !== 'mosaic' ? 'grey--text' : ''">
                mdi-view-module
              </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="displayMode = 'list'"
            >
              <v-icon :class="displayMode !== 'list' ? 'grey--text' : ''">
                mdi-view-list
              </v-icon>
            </v-btn>
            <!-- End display mode toggles -->
            <!-- Search field -->
            <v-text-field
              v-model="searchFilter"
              :label="$t('search')"
              hide-details
              autofocus
              single-line
              class="pt-0 ml-4"
              append-icon="mdi-magnify"
            />
            <!-- End search field-->
          </div>
          <!-- End Filters -->
        </v-col>
      </v-row>
      <!-- List of documents, depending on display mode -->
      <v-fade-transition
        v-if="activeCategory"
        mode="out-in"
      >
        <documents-cards-list
          v-if="displayMode === 'card'"
          :active-category="activeCategory"
          :documents="filteredDocuments"
          :documents-unread="documentsUnread"
          @documentRead="(index) => $emit('documentRead', index)"
        />
        <documents-mosaic-list
          v-else-if="displayMode === 'mosaic'"
          :active-category="activeCategory"
          :documents="filteredDocuments"
          :documents-unread="documentsUnread"
        />
        <documents-list-list
          v-else-if="displayMode === 'list'"
          :active-category="activeCategory"
          :documents="filteredDocuments"
          :documents-unread="documentsUnread"
        />
      </v-fade-transition>
    </v-container>
    <!-- End documents list -->
    <!-- Documents viewer lightbox -->
    <CoolLightBox 
      :items="lightboxDocuments" 
      :index="selectedDocument"
      src-name="src"
      @close="selectedDocument = null"
      @on-change="(idx) => selectedDocument = idx"
      @contextmenu.native="disableRightClick"
    />
    <!-- End documents viewer lightbox -->
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import MainMenu from '../components/MainMenu.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import DocumentsCardsList from '@/components/DocumentsCardsList.vue';
import DocumentsMosaicList from '@/components/DocumentsMosaicList.vue';
import DocumentsListList from '@/components/DocumentsListList.vue';
import { isMobile } from 'mobile-device-detect';
import NavigationDrawer from '../components/NavigationDrawer.vue';

/* eslint-disable no-undef */
export default {
  name: 'Main',
  components: {
    CoolLightBox,
    MainMenu,
    Breadcrumb,
    DocumentsCardsList,
    DocumentsMosaicList,
    DocumentsListList,
    NavigationDrawer
  },
  props: {
    documentsUnread: {
      type: Array,
      default: () => []
    },
  },
  /**
   * @var {boolean} isMobile for responsiveness
   * @var {array<object>} documents documents to display for the current category
   * @var {boolean} categoriesLoading whether categories have been fetched or not
   * @var {string} searchFilter search value for documents
   * @var {number} selectedDocument document id to display in viewer
   * @var {number} deletingFile id of the document currently being deleted (to display spinner)
   * @var {number} deletingFolder id of the category currently being deleted (to display spinner)
   * @var {string} displayMode display mode to use for documents
   * @var {object} renamedDocument document selected to be renamed
   * @var {boolean} renameDialog toggles document renaming dialog
   * @var {object} documentDenyAccess selected document to apply access restrictions to users
   * @var {object} categoryDenyAccess selected category to apply access restrictions to users
   * @var {boolean} denyDialog toggles document/category access restriction dialog
   * @var {boolean} patchingDocument whether a patch request is being submitted (to display spinner)
   * @var {boolean} updatingAccess whether a patch request is being submitted (to display spinner)
   * @var {array<object>} users list of users to use in users restriction select input
   * @var {array<object>} denyAccessModel ?
   * @var {object} denyCategory selected category to restrict access to users 
   * @var {string} apiUrl base url of api
   */
  data(){
    return {
      isMobile,
      categories: [],
      documents: [],
      categoriesLoading: true,
      loading: true,
      searchFilter: null,
      selectedDocument: null,
      activeCategory: null,
      displayMode: localStorage.getItem('LSF_EXTRANET_DISPLAY_MODE') ? localStorage.getItem('LSF_EXTRANET_DISPLAY_MODE') : 'list',
      apiUrl: process.env.VUE_APP_BASE_API_PUBLIC_URL,
    };
  },
  computed: {
    sortedCategories(){
      let cats = [];
      let extractSubCats = (mainCat, category, level) => {
          this.categories.forEach(cat => {
              if(cat.parent?._id === category._id){
                  mainCat.children.push({...cat, level});
                  if(this.categories.find(ctgry => ctgry.parent?._id === cat._id)){
                      extractSubCats(mainCat, cat, level+1);
                  }
              }
          });
      };
      this.categories.forEach(cat => {
      if(!cat.parent || cat.parent === undefined){
          cat.children = [];
          cats.push(cat);
          extractSubCats(cat, cat, 1);
      }
      });
      return cats;
    },
    isDownloadable(){
        if(this.activeCategory.isDownloadable && this.$store.state.user.role.permissions.extranet.categoriesPermissions.find(perm => perm.category === this.activeCategory._id).authorizations.download){
          return true;
        }
        return false;
      },
    filteredDocuments(){
      return this.documents;
    },
    /**
     * @var {array<object>} lightboxDocuments
     * List of documents to be displayed in lightbox (general catalog is excluded)
     * Parsed for CoolLightBox component usage (must be of mediaType 'iframe' for pdfs)
     */
    lightboxDocuments(){
      return this.filteredDocuments
        .map(doc => {
        if(!doc.catalogItem){
          return {
            src: `${this.apiUrl}${doc.path}`,
            mediaType: doc.contentType.includes('pdf') ? 'iframe' : doc.contentType.includes('video') ? 'video' : 'image'
          };
        }
      });
    },
  },
  watch: {
    /**
     * If selected document exists : we check if the document was previously unseen
     * If so, we remove its unseen status from store and we update the database to set it as read
     * We also send to API a new document view history
     */
    selectedDocument(){
      if(this.selectedDocument !== null && this.$store.state.user?.documentsUnread){
        const unreadDocument = this.filteredDocuments[this.selectedDocument];
        const index = this.$store.state.user.documentsUnread.findIndex(monr => monr._id === unreadDocument._id);
        if(index > -1 && this.$store.state.user.documentsUnread[index].read !== true){
          let user = {...this.$store.state.user};
          user.documentsUnread.splice(index,1);
          this.axios.put(`extranet-users/${this.$store.state.user._id}`, {documentsUnread: user.documentsUnread}).then(() => {
            this.$nextTick();
            this.$forceUpdate();
          });
        }
      }
      if(this.selectedDocument !== null){
        this.axios.post('extranet-histories', {
          user: this.$store.state.user._id,
          type: 'view',
          document: this.filteredDocuments[this.selectedDocument]._id
        });
      }
    },
    displayMode(){
      localStorage.setItem('LSF_EXTRANET_DISPLAY_MODE', this.displayMode);
    },
    '$store.state.user': function(){
      if(this.$store.state.user){
        this.axios.get('extranet-categories').then(res => {
        this.categories = res.data.filter(cat => this.$store.state.user.role.permissions.extranet.categoriesPermissions.find(perm => perm.category === cat._id)?.authorizations.read);
        let cats = [...this.categories];
        let activeCategory = cats.filter(cat => !cat.parent).sort((a, b) => a.orderId - b.orderId)[0];
        this.loadDocuments(activeCategory);
      });
      }
    }
  },
  /**
   * We fetch categories, then users and users last login history. If last login was before today, we send an update for last login
   * @TODO login check should be done on backend / user.lastLogin now available (no need for request)
   */
  async created(){
    // this.$store.dispatch('getUser');
    if(this.$store.state.user){
      this.axios.get('extranet-categories').then(res => {
        this.categories = res.data.filter(cat => this.$store.state.user.role.permissions.extranet.categoriesPermissions.find(perm => perm.category === cat._id)?.authorizations.read);
        let cats = [...this.categories];
        let activeCategory = cats.filter(cat => !cat.parent).sort((a, b) => a.orderId - b.orderId)[0];
        this.loadDocuments(activeCategory);
      });
    } else {
      this.axios.post('extranet-users/current').then(res => {
        this.$store.commit('setUser', res.data);
      });
    }
  },
  methods: {
    seeAll(category){
      this.activeCategory = category;
      this.documents = [];
      let ids = [category._id];
      for(let i = 0; i < this.sortedCategories.length; i++){
        if(category._id.toString() === this.sortedCategories[i]._id.toString()){
          this.sortedCategories[i].children.forEach(child => ids.push(child._id));
        }
      }
      this.axios.get(`extranet-categories?ids=${ids.join(',')}&documents=true`).then(res => {
        let docs = [];
        this.documents = res.data.forEach(cat => {
          docs = [...docs, ...cat.documents];
        });
        this.documents = docs.sort((a, b) => this.$i18n.locale === 'en' ? a.nameEN || a.name - b.nameEN || b.name : a.name - b.name);
      });
    },
    loadDocuments(category){
      this.activeCategory = category;
      this.$store.commit('setDrawer', false);
      this.documents = [];
      this.axios.get(`extranet-categories/${category._id}`).then(res => {
        this.documents = res.data.documents.sort((a, b) => this.$i18n.locale === 'en' ? a.nameEN || a.name - b.nameEN || b.name : a.name - b.name);
      });
    },
    /**
     * Disables browser context menu for admins and documents not downloadable
     */
    disableRightClick(e){
      if(!this.isDownloadable){
        e.preventDefault();
      }
    }
  }
};
</script>
