import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  /**
   * @var {string} locale to be deleted, @TODO should use vue instance's $i18n.locale
   * @var {object} user logged in user
   * @var {object} activeCategory category currently browsed by user
   * @var {string} displayMode display mode of extranet's documents (mosaic, list or card)
   * @var {number} unreadCount count of documents never viewed by current user @TODO could be a getter only
   */
  state: {
    locale: 'fr',
    user: null,
    drawer: false,
    activeCategory: null,
    displayMode: localStorage.getItem('LSF_EXTRANET_DISPLAY_MODE') ? localStorage.getItem('LSF_EXTRANET_DISPLAY_MODE') : 'list',
    unreadCount: null,
    snackbar: {show: false},
  },
  /**
   * @var {boolean} isAdmin to check if current user is an admin
   */
  getters: {
      locale: state => state.locale,
      user: state => state.user,
      activeCategory: state => state.activeCategory,
      displayMode: state => state.displayMode,
      unreadCount: state => state.unreadCount
  },
  mutations: {
    setDrawer(state, val){
      state.drawer = val;
    },
    setLocale(state, locale){
      state.locale = locale;
    },
    setSnackbar(state, val){
      state.snackbar = val;
    },
    /** 
     * @param {object} user
     * Sets state user and add it to localStorage for next visit
     * Also sets unreadCount from user.mediaObjectsNotRead length 
     */
    setUser(state, user){
      state.user = user;
    },
    setActiveCategory(state, category){
      state.activeCategory = category;
    },
    /**
     * @param {string} displayMode
     * Sets display mode of documents and stores in localStorage for next visit 
     */
    setDisplayMode(state, displayMode){
      state.displayMode = displayMode;
      localStorage.setItem('LSF_EXTRANET_DISPLAY_MODE', displayMode);
    },
    removeUnreadDoc(state, index){
      state.user.mediaObjectsNotRead[index].read = true;
      state.unreadCount--;
    }
  },
  actions: {
    /**
     * Removes freshly viewed doc from state and updates database
     * @TODO should be done the other way, backend first, state if successful (bad UX ?)
     */
    removeUnreadDoc({state, commit}, index){
      commit('removeUnreadDoc', index);
      const doc = state.user.mediaObjectsNotRead[index];
      state.api.get(`media-read/${doc.id}`);
    },
    /**
     * @param {string} locale
     * @TODO to deprecate, should use vue instance global $i18n.locale 
     */
    switchLocale({ commit }, locale){
      commit('setLocale', locale);
    },
    setSnackbar({commit}, {show, text, type}){
      let snackbar = {
        show,
        text
      };

      if(type === 'success'){
        snackbar.color = 'success';
        snackbar.timeout = 2000;
      }

      if(type === 'error'){
        snackbar.color = 'error';
        snackbar.timeout = 5000;
      }
      commit('setSnackbar', snackbar);
    },
  }
});

export default store;