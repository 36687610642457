<template>
  <v-navigation-drawer
    v-model="drawerOpen"
    app
    temporary
  >
    <!-- Drawer header title -->
    <v-list-item
      class="primary white--text"
      style="height: 64px;"
    >
      <v-list-item-content>
        <v-list-item-title
          v-if="user"
          class="text-h6"
        >
          {{ user.firstName }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- End drawer header title -->
    <v-divider />
    <!-- Documents display mode toggle (mobile only) -->
    <v-list-item
      v-if="isMobile"
      style="background-color: rgba(0,0,0,.03)"
      class="d-flex w-100"
    >
      <span>
        {{ $t('display') }}:
      </span>
      <v-spacer />
      <v-icon
        class="pl-2"
        :class="$store.getters.displayMode !== 'card' ? 'grey--text' : ''"
        @click="() => {$store.commit('setDrawer', false); $store.commit('setDisplayMode', 'card'); }"
      >
        mdi-view-grid
      </v-icon>
      <v-icon
        class="pl-2"
        :class="$store.getters.displayMode !== 'mosaic' ? 'grey--text' : ''"
        @click="() => {$store.commit('setDrawer', false); $store.commit('setDisplayMode', 'mosaic'); }"
      >
        mdi-view-module
      </v-icon>
    </v-list-item>
    <!-- End documents display mode -->
    <!-- Categories menu (mobile only) -->
    <main-menu
      v-if="isMobile"
      :categories="categories"
      @toggleDrawer="value => $store.commit('setDrawer', value)"
      @categoryClick="$parent.loadDocuments"
      @seeAll="$parent.seeAll"
    />
    <!-- End categories menu -->
    <v-divider v-if="isMobile" />
    <!-- Backoffice navigation (admin only) + logout button -->
    <v-list
      dense
      nav
    >
      <v-list-item
        link
        @click="logout"
      >
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- End backoffice navigation (admin only) + logout button -->
  </v-navigation-drawer>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import MainMenu from './MainMenu.vue';

export default {
    components: {
        MainMenu
    },
    props: {
        categories: {
          type: Array,
          required: true
        }
    },
    /**
     * @var {boolean} isMobile if displayed on mobile resolution
     */
    data(){
        return {
            isMobile,
        };
    },
    computed: {
        /**
         * @var {boolean} drawerOpen Same as drawer prop
         */
        drawerOpen: {
            get(){
                return this.$store.state.drawer;
            },
            set(val){
                this.$store.commit('setDrawer', val);
            }
        },
        /**
         * @var {object} user current logged in user
         */
        user(){
            return this.$store.state.user;
        },
        /**
         * @var {object} isAdmin whether current user is admin
         */
        isAdmin(){
            return this.$store.getters.isAdmin;
        },
    },
    methods: {
        /**
         * Logs out user
         * Deletes cookied from localStorage
         * Redirects to login view
         */
        logout(){
          this.$store.commit('setUser', null);
          localStorage.removeItem('LSF_EXTRANET_ACCESS_TOKEN');
          localStorage.removeItem('LSF_EXTRANET_REFRESH_TOKEN');
          this.$router.push('/login');
        }
    }
};
</script>