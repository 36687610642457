import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '@/views/Main.vue';
import Login from '@/views/Login.vue';
import ResetPassword from '@/views/ResetPassword.vue';
// import store from '@/store';
// import _axios from '@/plugins/axios';
// import moment from 'moment';

Vue.use(VueRouter);

/**
 * @TODO Meta tags are supposedly translatable
 */

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Connexion | Extranet - La Spiriterie Française',
      metaTags: [
        {
          name: 'description',
          content: 'Application extranet de la Spiriterie Française - Château du Breuil Normandie'
        },
      ]
    }
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
    meta: {
      title: 'Réinitialisation du mot de passe | Extranet - La Spiriterie Française',
      metaTags: [
        {
          name: 'description',
          content: 'Application extranet de la Spiriterie Française - Château du Breuil Normandie'
        },
      ]
    }
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      title: 'Extranet - La Spiriterie Française',
      metaTags: [
        {
          name: 'description',
          content: 'Application extranet de la Spiriterie Française - Château du Breuil Normandie'
        },
        {
          property: 'og:description',
          content: 'Application extranet de la Spiriterie Française - Château du Breuil Normandie'
        }
      ]
    }
  }
];

/* eslint-disable no-undef */
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  // if(to.name !== 'login' && to.name !== 'Reset Password'){
  //   let accessToken = localStorage.getItem('LSF_EXTRANET_ACCESS_TOKEN');
  //   let refreshToken = localStorage.getItem('LSF_EXTRANET_REFRESH_TOKEN');
  //   if (!store.state.user && accessToken && refreshToken) {
  //     console.log(accessToken, refreshToken);
  //     const res = await _axios.post('extranet-users/current');
  //     if(res.data){
  //       store.commit('setUser', res.data);
  //     }
  //     next();
  //   }
  // }
  next();
});

export default router;
