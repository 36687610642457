<template>
  <v-app>
    <!-- Top Bar -->
    <v-app-bar
      v-if="$route.name !== 'login' && $route.name !== 'Reset Password'"
      color="primary"
      elevation="0"
      app
    >
      <v-app-bar-nav-icon
        v-if="isMobile"
        class="white--text"
        @click="$store.commit('setDrawer', true)"
      />

      <v-toolbar-title
        class="d-flex align-center main-logo"
      >
        <img
          class="my-auto"
          :src="require('@/assets/images/logo-lsf.svg')"
          height="46"
          width="300"
        >
      </v-toolbar-title>
      <v-spacer />
      <!-- Right Action buttons -->
      <!-- Unread Documents Alert buttons -->
      <v-menu
        v-if="!isMobile"
        offset-y
        bottom
        left
      >
        <template #activator="{on, attrs}">
          <v-btn
            icon
            class="ml-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-badge
              v-if="alertDocumentsUnread && alertDocumentsUnread.filter(doc => !doc.read).length"
              overlap
              size="10"
              color="success"
              :content="alertDocumentsUnread.filter(doc => !doc.read).length"
            >
              <v-icon color="white">
                mdi-bell
              </v-icon>
            </v-badge>
            <v-icon
              v-else
              color="white"
            >
              mdi-bell
            </v-icon>
          </v-btn>
        </template>
        <!-- List of unread documents -->
        <v-list v-if="alertDocumentsUnread && alertDocumentsUnread.length">
          <v-list-item
            v-for="(unreadDoc, idx) in alertDocumentsUnread"
            :key="idx"
            @click="selectedUnreadDocument = idx"
          >
            <v-list-item-icon class="mr-0">
              <!-- Icon depends on document type -->
              <v-icon
                v-if="unreadDoc.contentType.includes('pdf')"
                color="red"
              >
                mdi-file-box
              </v-icon>
              <v-icon
                v-else-if="unreadDoc.contentType.includes('vnd')"
                color="success"
              >
                mdi-file-excel
              </v-icon>
              <v-icon v-else-if="unreadDoc.contentType.includes('image')">
                mdi-file-image
              </v-icon>
              <v-icon v-else>
                mdi-file
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <v-chip
                v-if="!unreadDoc.read"
                class="mx-2"
                label
                x-small
                color="primary"
              >
                NEW
              </v-chip>{{ $i18n.locale === 'en' ? unreadDoc.nameEN || unreadDoc.name : unreadDoc.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <!-- End list of unread documents -->
      </v-menu>
      <!-- End Unread documents alert button -->
      <!-- Language switch menu -->
      <v-menu
        v-if="!isMobile"
        offset-y
        bottom
        left
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            class="mr-2 ml-2"
            v-bind="attrs"
            v-on="on"
          >
            <img
              :src="require(`@/assets/images/${$i18n.locale === 'fr' ? 'france' : 'uk'}-flag.png`)"
              height="35px"
            >
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$i18n.locale = 'fr'">
            <v-list-item-icon>
              <v-img
                contain
                src="@/assets/images/france-flag.png"
              />
            </v-list-item-icon>
            <v-list-item-title>Français</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$i18n.locale = 'en'">
            <v-list-item-icon>
              <v-img
                contain
                src="@/assets/images/uk-flag.png"
              />
            </v-list-item-icon>
            <v-list-item-title>English</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- End Language Switch Menu -->
      <!-- User Signout menu -->
      <v-menu
        v-if="$store.state.user && $store.state.user.firstName && !isMobile"
        offset-y
        nudge-bottom="8"
        bottom
        left
      >
        <template #activator="{ on, attrs }">
          <v-chip 
            label
            class="white--text ml-2"
            color="info"
            v-bind="attrs"
            v-on="on"
          >
            <strong>{{ $store.state.user.firstName }} {{ $store.state.user.lastName }}</strong>
          </v-chip>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-icon class="mr-0">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-2">
              {{ $t('logout') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- End User Signout menu -->
      <!-- End Right Action buttons -->
    </v-app-bar>
    <!-- Views container -->
    <v-main>
      <v-container
        fluid
        class="h-100 pa-0"
      >
        <v-fade-transition hide-on-leave>
          <router-view
            :documents-unread="alertDocumentsUnread"
            @documentRead="documentRead"
          />
        </v-fade-transition>
      </v-container>
    </v-main>
    <!-- End Views container -->
    <!-- Footer privacy notice (desktop only) -->
    <v-footer
      v-if="!isMobile && $route.name !== 'login' && $route.name !== 'Reset Password'"
      class="primary-bg"
    >
      <img
        :src="require('@/assets/images/logo-footer.svg')"
        class="mr-2"
        height="30"
        width="30"
      ><h5>
        {{ $t('footerDisclaimer') }}
      </h5>
    </v-footer>
    <!-- End footer privacy notice -->
    <!-- App wide documents viewer in lightbox -->
    <CoolLightBox 
      :items="unreadDocuments" 
      :index="selectedUnreadDocument"
      src-name="src"
      @on-change="(idx) => selectedUnreadDocument = idx"
      @close="selectedUnreadDocument = null"
    />
    <!-- End app wide documents viewer in lightbox -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="$store.dispatch('setSnackbar', {show: false})"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { mapState } from 'vuex';
import CoolLightBox from 'vue-cool-lightbox';

export default {
  components:{
    CoolLightBox,
  },
  /**
   * 
   * @var {Boolean} isMobile
   * Determines on page load if device is mobile or above (tablet, desktop)
   * TODO: better responsiveness for tablet (isTablet is available in mobile-device-detect package)
   * 
   * @var {Number} selectedUnreadDocument
   * id of selected unread document, used by CoolLightBox
   */
  data(){
    return {
      isMobile,
      selectedUnreadDocument: null,
      documentsRead: [],
      alertDocumentsUnread: null
    };
  },
  /* eslint-disable no-undef */
  computed: {
      ...mapState([
        'snackbar',
      ]),
      apiUrl(){
        return process.env.VUE_APP_BASE_API_PUBLIC_URL;
      },
      /**
       * @var {Array<Object>} unreadDocuments
       * Array of documents not viewed by user.
       * iframe must be specified as mediatype for pdfs for CoolLightbox to display them correctly
       */
      unreadDocuments(){
        if(this.alertDocumentsUnread){
          return this.alertDocumentsUnread
            .map(unreadDoc => {
              return {
                src: `${this.apiUrl}${unreadDoc.path}`,
                mediaType: unreadDoc.contentType.includes('pdf') ? 'iframe' : unreadDoc.contentType.includes('video') ? 'video' : 'image',
              };
            });
        }
        return [];
      },
  },
  watch: {
    '$store.state.user': function(){
      if(this.$store.state.user?.documentsUnread){
        this.alertDocumentsUnread = [...this.$store.state.user.documentsUnread];
      }
    },  
    /**
     * When viewing an unread document, we navigate to the document's category (by setting store category)
     * We also remove the document from the unread documents array
     * The store action also takes care to remove the document's unread status from db
     */
    selectedUnreadDocument(){
      if(this.selectedUnreadDocument !== null && this.$store.state.user?.documentsUnread){
        const unreadDocument = this.alertDocumentsUnread[this.selectedUnreadDocument];
        const index = this.alertDocumentsUnread.findIndex(monr => monr._id === unreadDocument._id);
        this.alertDocumentsUnread[this.selectedUnreadDocument].read = true;
        if(index > -1){
          let user = {...this.$store.state.user};
          user.documentsUnread.splice(index,1);
          this.axios.put(`extranet-users/${this.$store.state.user._id}`, {documentsUnread: user.documentsUnread}).then(() => {
            this.$nextTick();
            this.$forceUpdate();
          });
          this.axios.post('extranet-histories', {
            user: this.$store.state.user._id,
            type: 'view',
            document: unreadDocument._id
          });
        }
      }
    }
  },
  mounted(){
    // if(!this.$store.state.user && this.$route.name !== 'login'){
    //   console.log('no user');
    //   this.$router.push('/login');
    // }
  },
  created(){
    if(localStorage.getItem('LSF_EXTRANET_USER')){
      localStorage.removeItem('LSF_EXTRANET_USER');
    }
    if(localStorage.getItem('LSF_EXTRANET_TOKEN')){
      localStorage.removeItem('LSF_EXTRANET_TOKEN');
    }
  },  
  methods: {
    /**
     * @return {void}
     * @param {string} locale 
     * Changes the app's language
     */
    changeLocale(locale){
      this.$i18n.locale = locale;
      this.$store.dispatch('switchLocale', locale);
    },
    /**
     * @return {void}
     * Logs user out, erases localStorage data, empties store user content
     * and redirects to login view
     */
    logout(){
      this.$store.commit('setUser', null);
      localStorage.removeItem('LSF_EXTRANET_ACCESS_TOKEN');
      localStorage.removeItem('LSF_EXTRANET_REFRESH_TOKEN');
      this.$router.push('/login');
    },
    documentRead(index){
      this.alertDocumentsUnread[index].read = true;
      this.$nextTick();
      this.$forceUpdate();
    }
  }
};
</script>
